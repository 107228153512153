<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card">
            <div class="card-header">
                <h5>
                    المواعيد
                </h5>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-bordered table-sm">
                        <thead>
                            <th>المسوق</th>
                            <th>المدرسة</th>
                            <th>اليوم</th>
                            <th>الساعة</th>
                            <th>
                                السبب
                            </th>
                            <th>
                                سجل النشاط
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="u in calendars" :key="u._id">
                                <td>
                                    {{ u.name }}
                                </td>
                                <td>
                                    {{ u.schoolname }}
                                </td>
                                <td>
                                    {{ u.date }}
                                </td>
                                <td>
                                    {{ u.time }}
                                </td>
                                <td>
                                    {{ u.reason }}
                                </td>
                                <td>
                                    <button class="btn btn-sm btn-primary" v-b-modal.logs @click="logs = u.logs; current = u">
                                        <i class="fa fa-list"></i>
                                        عرض ({{ u.logs.length }})
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="logs" title="سجل النشاط" hide-footer>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover table-bordered">
                <thead>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        التفاصيل
                    </th>
                </thead>
                <tbody>
                    <tr v-for="(l, i) in logs" :key="i">
                        <td>
                            {{ l.date }}
                        </td>
                        <td>
                            {{ l.content }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            calendars: [],
            create: {money: 0, date: new Date().toISOString().split("T")[0], interested: 0, time: new Date().toISOString().split("T")[1].split(".")[0].split(":")[0] + ":" + new Date().toISOString().split("T")[1].split(".")[0].split(":")[1]},
            logs: [],
            details: "",
            current: {},
            logg: ""
        }
    },
    created(){
        this.getR()
    },
    methods: {
        getR(){
            var g = this;
            $.post(api + '/admin/resellers/calendar/list', {
                jwt: localStorage.getItem("jwt")
            }).then(function(r){
                g.calendars = r.response;
            })
        },
    }
}
</script>

<style>

</style>